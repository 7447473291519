import DragIndicatorIcon from "@mui/icons-material/DragIndicatorRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import TypographyTooltipEllipsis from "../../../../../../../shared/components/TypographyTooltipEllipsis";
import { Category } from "../../../../../../api/types/accessTypes";

interface Props {
  category: Category;
  readOnly?: boolean;
  onEdit: (category: Category) => void;
}

const CategoryDragDropListItem = ({ category, readOnly, onEdit }: Props) => {
  return (
    <ListItem draggable={!readOnly}>
      <ListItemIcon sx={{ minWidth: "unset", mr: 0.75 }}>
        <DragIndicatorIcon color={readOnly ? "disabled" : "secondary"} sx={{ cursor: readOnly ? "pointer" : "grab" }} />
      </ListItemIcon>
      <TypographyTooltipEllipsis text={category.name} />
      <ListItemSecondaryAction>
        <IconButton size="small" disabled={readOnly} onClick={() => onEdit(category)}>
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default CategoryDragDropListItem;
