export type SortCompareFn<T> = (a: T, b: T) => number;

export const combineComparers =
  <T>(...comparers: SortCompareFn<T>[]): SortCompareFn<T> =>
  (a, b) =>
    comparers.reduce((result, compare) => (result !== 0 ? result : compare(a, b)), 0);

export const stringComparerBy =
  <TItem>(getValue: (item: TItem) => string): SortCompareFn<TItem> =>
  (a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);
    return !aValue ? 1 : !bValue ? -1 : aValue.localeCompare(bValue);
  };

export const numberComparerBy =
  <TItem>(getValue: (item: TItem) => number): SortCompareFn<TItem> =>
  (a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);
    return Number.isNaN(aValue) ? 1 : Number.isNaN(bValue) ? -1 : Math.sign(aValue - bValue);
  };

export const numberComparerDescBy =
  <TItem>(getValue: (item: TItem) => number): SortCompareFn<TItem> =>
  (a, b) => {
    const aValue = getValue(a);
    const bValue = getValue(b);
    return Number.isNaN(aValue) ? 1 : Number.isNaN(bValue) ? -1 : Math.sign(bValue - aValue);
  };

export const priorityComparer =
  <TItem>(hasPriority: (item: TItem) => boolean): SortCompareFn<TItem> =>
  (a, b) =>
    Number(hasPriority(b)) - Number(hasPriority(a));

export const arraysHaveSameItems = <T>(a: T[], b: T[]): boolean => {
  const setA = new Set(a);
  const setB = new Set(b);
  if (setA.size !== setB.size) {
    return false;
  }
  for (const item of setA.entries()) {
    if (!setB.has(item[0])) {
      return false;
    }
  }
  return true;
};

export const sumBy = <TItem>(array: TItem[], getValue: (i: TItem) => number): number =>
  array.reduce((result, item) => result + getValue(item), 0);

export const minBy = <TItem>(array: TItem[], getValue: (i: TItem) => number): number => {
  const firstEl = array[0];
  if (!firstEl) {
    return 0;
  }

  return array.reduce((result, item) => (getValue(item) < result ? getValue(item) : result), getValue(firstEl));
};

export const maxBy = <TItem>(array: TItem[], getValue: (i: TItem) => number): number => {
  const firstEl = array[0];
  if (!firstEl) {
    return 0;
  }

  return array.reduce((result, item) => (getValue(item) > result ? getValue(item) : result), getValue(firstEl));
};

export const groupBySum = <TItem>(
  array: TItem[],
  getKey: (i: TItem) => string,
  getValue: (i: TItem) => number
): Record<string, number> =>
  array.reduce<Record<string, number>>((result, item) => {
    const key = getKey(item);
    if (result[key] === undefined) {
      result[key] = getValue(item);
    } else {
      result[key] += getValue(item);
    }

    return result;
  }, {});

export const groupByToMap = <K, V>(array: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> => {
  const map = new Map();
  array.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const distinctBy = <T, K>(array: T[], getKey: (item: T) => K): T[] => {
  const seen = new Set<K>();
  return array.filter((item) => {
    const key = getKey(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

export const distinct = <T>(array: T[]): T[] => [...new Set(array)];

export const intersect = <T>(a: T[], b: T[]): T[] => distinct(a.filter((value) => b.includes(value)));

export const except = <T>(a: T[], b: T[]): T[] => distinct(a.filter((value) => !b.includes(value)));
