import { FileAccessOption } from "../../../../../../../shared/api/fundraisingTypes";
import { ApiError } from "../../../../../../../shared/api/types";
import { combineComparers, numberComparerBy } from "../../../../../../../shared/utilities/arrayHelper";
import { isImageFile, isPdfFile } from "../../../../../../../shared/utilities/fileHelper";
import { generateGuid } from "../../../../../../../shared/utilities/generateGuid";
import { fileToUploadValidator } from "../../../../../../../shared/utilities/validators";
import { Category } from "../../../../../../api/types/accessTypes";
import {
  FundraisingFile,
  FundraisingFileUpdate,
  UploadFundraisingFileRequest,
} from "../../../../../../api/types/fundraisingTypes";

export interface FundraisingDocumentsState {
  documents: FundraisingDocument[];
  searchTerm: string;
  isAnyDocumentUpdated: boolean;
  isSaving?: boolean;
}

export interface FundraisingDocument {
  fileId: string;
  name: string;
  size: number;
  externalCategoryId?: string;
  section?: string;
  uploadedAt?: string;
  file?: File;
  uploadStatus?: UploadFileStatus;
  validationError?: string;
  uploadError?: ApiError;
  isDownloading?: boolean;
  accessOptions?: FileAccessOption[];
}

export type UploadFileStatus = "ready_for_upload" | "uploading" | "upload_completed" | "error";

const sortFiles = <T extends { externalCategoryId?: string }>(files: T[], categories: Category[]): T[] =>
  files
    .map((doc, docIndex) => ({
      ...doc,
      docIndex,
      categoryOrder: categories.find((c) => c.externalId === doc.externalCategoryId)?.sortOrder ?? 1_000_000,
    }))
    .sort(
      combineComparers(
        numberComparerBy((d) => d.categoryOrder),
        numberComparerBy((d) => d.docIndex)
      )
    );

export const getInitialFundraisingDocumentsState = (
  files: FundraisingFile[],
  categories: Category[]
): FundraisingDocumentsState => ({
  documents: sortFiles(files, categories),
  searchTerm: "",
  isAnyDocumentUpdated: false,
});

// Selectors

export const getFilteredDocuments = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.searchTerm === ""
    ? [...state.documents]
    : state.documents.filter((doc) => doc.name.toLowerCase().includes(state.searchTerm));

const getDocumentsForUpload = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.documents.filter((doc) => doc.uploadStatus === "ready_for_upload" && doc.file);

const getDocumentsWithErrors = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.documents.filter((doc) => doc.uploadStatus === "error" && doc.file);

const isPersistedDocument = (doc: FundraisingDocument): boolean => doc.uploadStatus === undefined;

interface UploadFundraisingFileRequestWithFileId extends UploadFundraisingFileRequest {
  fileId: string;
}

export const getUploadRequests = (state: FundraisingDocumentsState): UploadFundraisingFileRequestWithFileId[] => {
  const documentsForUpload = getDocumentsForUpload(state);
  return documentsForUpload.map(({ fileId, file, externalCategoryId, section, accessOptions }) => ({
    fileId,
    file: file as File,
    externalCategoryId,
    section,
    accessOptions,
  }));
};

export const getUpdateRequests = (
  state: FundraisingDocumentsState,
  uploadedFileIdMap: Record<string, string>
): FundraisingFileUpdate[] => {
  const uploadedFileIds = Object.keys(uploadedFileIdMap);
  const documentsToUpload = state.documents.filter(
    (doc) => isPersistedDocument(doc) || uploadedFileIds.includes(doc.fileId)
  );

  // Always send all persisted files to the server; the absence means the file should be deleted
  return documentsToUpload.map((doc) => ({
    fileId: uploadedFileIdMap[doc.fileId] ?? doc.fileId,
    externalCategoryId: doc.externalCategoryId,
    section: doc.section,
    accessOptions: doc.accessOptions,
  }));
};

export const doChangesExist = (state: FundraisingDocumentsState): boolean =>
  state.isAnyDocumentUpdated || getDocumentsForUpload(state).length > 0;

export const doChangesOrErrorsExist = (state: FundraisingDocumentsState): boolean =>
  doChangesExist(state) || getDocumentsWithErrors(state).length > 0;

export const isChangeAccessOptionsAllowed = (doc: FundraisingDocument) => isPdfFile(doc.name) || isImageFile(doc.name);

export const selectAccessOptions: [string, FileAccessOption[]][] = [
  ["View", ["View"]],
  ["View & Download", ["View", "Download"]],
];

// Actions

type StateAction = (state: FundraisingDocumentsState) => FundraisingDocumentsState;

export const searchAction =
  (value: string): StateAction =>
  (state) => ({ ...state, searchTerm: value.trim().toLowerCase() });

export const startSavingAction = (): StateAction => (state) => ({ ...state, isSaving: true });

export const finishSavingAction =
  (updatedFiles: FundraisingFile[], categories: Category[]): StateAction =>
  (state) => ({
    documents: [...state.documents.filter((d) => d.uploadStatus === "error"), ...sortFiles(updatedFiles, categories)],
    searchTerm: "",
    isAnyDocumentUpdated: false,
  });

export const startDownloadingFileAction =
  (fileId: string): StateAction =>
  (state) => ({
    ...state,
    documents: state.documents.map((doc) => (doc.fileId === fileId ? { ...doc, isDownloading: true } : doc)),
  });

export const stopDownloadingFileAction = (): StateAction => (state) => ({
  ...state,
  documents: state.documents.map((doc) => ({ ...doc, isDownloading: false })),
});

export const maxFileSize = 50 * 1024 * 1024;

export const acceptedFileExtensions = [
  ".pdf",
  ".docx",
  ".doc",
  ".xlsx",
  ".xlsm",
  ".xls",
  ".pptx",
  ".ppt",
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
];

const validateFile = fileToUploadValidator({
  maxFileSize,
  acceptedFileExtensions,
});

export const addDocumentsToUploadAction =
  (files: FileList): StateAction =>
  (state) => {
    const newDocuments: FundraisingDocument[] = [];

    for (const file of files) {
      const validationResult = validateFile(file);

      newDocuments.push({
        file,
        fileId: generateGuid(),
        name: file.name,
        size: file.size,
        uploadStatus: validationResult.isValid ? "ready_for_upload" : "error",
        validationError: validationResult.error,
      });
    }

    return { ...state, documents: [...newDocuments, ...state.documents] };
  };

export const updateDocumentsCategoryAction =
  (fileIds: string[], categories: Category[], externalCategoryId: string, section: string | undefined): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.find((doc) => fileIds.includes(doc.fileId));

    const isAnyDocumentUpdated =
      state.isAnyDocumentUpdated || (updatedDocuments !== undefined && isPersistedDocument(updatedDocuments));

    const newDocuments = state.documents.map((doc) =>
      fileIds.includes(doc.fileId) ? { ...doc, externalCategoryId, section } : doc
    );

    const notPersistedDocuments = newDocuments.filter((doc) => !isPersistedDocument(doc));
    const persistedDocuments = newDocuments.filter(isPersistedDocument);

    return {
      ...state,
      documents: [...notPersistedDocuments, ...sortFiles(persistedDocuments, categories)],
      isAnyDocumentUpdated,
    };
  };

export const updateDocumentsAccessOptionsAction =
  (fileIds: string[], accessOptions: FileAccessOption[], categories: Category[]): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.find((doc) => fileIds.includes(doc.fileId));
    const isAnyDocumentUpdated =
      state.isAnyDocumentUpdated || (updatedDocuments !== undefined && isPersistedDocument(updatedDocuments));

    const newDocuments = state.documents.map((doc) => (fileIds.includes(doc.fileId) ? { ...doc, accessOptions } : doc));

    const notPersistedDocuments = newDocuments.filter((doc) => !isPersistedDocument(doc));
    const persistedDocuments = newDocuments.filter(isPersistedDocument);

    return {
      ...state,
      documents: [...notPersistedDocuments, ...sortFiles(persistedDocuments, categories)],
      isAnyDocumentUpdated,
    };
  };

export const uploadInProgressAction =
  (fileId: string): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "uploading" as UploadFileStatus } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const uploadErrorAction =
  (fileId: string, error: ApiError): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "error" as UploadFileStatus, uploadError: error } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const uploadCompletedAction =
  (fileId: string): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "upload_completed" as UploadFileStatus } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const deleteDocumentsAction =
  (fileIds: string[]): StateAction =>
  (state) => {
    const removedDocuments = state.documents.filter((doc) => fileIds.includes(doc.fileId));
    const newDocuments = state.documents.filter((doc) => !fileIds.includes(doc.fileId));
    const isAnyDocumentUpdated = state.isAnyDocumentUpdated || removedDocuments.some(isPersistedDocument);
    return { ...state, documents: newDocuments, isAnyDocumentUpdated };
  };

export const moveDocumentUpAction =
  (fileId: string): StateAction =>
  (state) => {
    const index = state.documents.findIndex((doc) => doc.fileId === fileId);
    if (index <= 0) {
      return state;
    }

    const newDocuments = [...state.documents];

    [newDocuments[index - 1], newDocuments[index]] = [
      newDocuments[index] as FundraisingDocument,
      newDocuments[index - 1] as FundraisingDocument,
    ];

    return { ...state, documents: newDocuments, isAnyDocumentUpdated: true };
  };

export const moveDocumentDownAction =
  (fileId: string): StateAction =>
  (state) => {
    const index = state.documents.findIndex((doc) => doc.fileId === fileId);
    if (index >= state.documents.length - 1) {
      return state;
    }

    const newDocuments = [...state.documents];

    [newDocuments[index], newDocuments[index + 1]] = [
      newDocuments[index + 1] as FundraisingDocument,
      newDocuments[index] as FundraisingDocument,
    ];

    return { ...state, documents: newDocuments, isAnyDocumentUpdated: true };
  };
