import { Alert, Stack } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import PreviewFileDialog from "../../../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../../../shared/services/downloadFile";
import adminApi from "../../../../../../api/adminApi";
import { FileDownloadInfo } from "../../../../../../api/types/fileTypes";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import FundraisingDocumentsActions from "./FundraisingDocumentsActions";
import { FundraisingDocumentsContextProvider } from "./FundraisingDocumentsContext";
import FundraisingDocumentsGrid from "./FundraisingDocumentsGrid";
import {
  FundraisingDocumentsState,
  getFilteredDocuments,
  getInitialFundraisingDocumentsState,
  startDownloadingFileAction,
  stopDownloadingFileAction,
} from "./fundraisingDocumentsState";
import FundraisingDocumentsUploadArea from "./FundraisingDocumentsUploadArea";

interface DialogState {
  openDialog?: "preview";
  fileInfo?: FileDownloadInfo;
}

const getFundraisingFileDownloadInfo = withErrorHandling(adminApi.getFundraisingFileDownloadInfo);
const getDownloadUrlForMultipleFundraisingFiles = withErrorHandling(adminApi.getDownloadUrlForMultipleFundraisingFiles);

const FundraisingDocuments = () => {
  const { sendNotificationError } = useNotificationContext();
  const { fundraising, fundraisingCategories } = useFundraisingDetailsPageContext();

  const [dialogState, setDialogState] = useState<DialogState>({});
  const [documentsState, setDocumentsState] = useState<FundraisingDocumentsState>(
    getInitialFundraisingDocumentsState(fundraising.files, fundraisingCategories)
  );

  const handlePreviewFile = async (fileId: string) => {
    setDocumentsState(startDownloadingFileAction(fileId));
    const [fileInfo, error] = await getFundraisingFileDownloadInfo(fundraising.id, fileId);
    setDocumentsState(stopDownloadingFileAction());

    if (error) {
      sendNotificationError("Could not preview the file");
      logError(error, "[FundraisingDocuments] getFundraisingFileDownloadInfo");
      return;
    }

    setDialogState({ openDialog: "preview", fileInfo });
  };

  const handleDownloadFile = async (fileId: string) => {
    setDocumentsState(startDownloadingFileAction(fileId));
    const [fileInfo, error] = await getFundraisingFileDownloadInfo(fundraising.id, fileId);
    setDocumentsState(stopDownloadingFileAction());

    if (error) {
      sendNotificationError("Could not download the file");
      logError(error, "[FundraisingDocuments] getFundraisingFileDownloadInfo");
      return;
    }

    downloadFileFromUrl(fileInfo.downloadUrl);
  };

  const handleDownloadMultipleFiles = async (fileIds: string[]) => {
    const [downloadUrl, error] = await getDownloadUrlForMultipleFundraisingFiles(
      fundraising.id,
      "fundraising_documents.zip",
      fileIds
    );

    if (error || !downloadUrl) {
      sendNotificationError("Could not download the files");
      logError(error, "[FundraisingDocuments] getDownloadUrlForMultipleFundraisingFiles");
      return;
    }

    downloadFileFromUrl(downloadUrl);
  };

  if (fundraisingCategories.length === 0) {
    return (
      <Alert severity="error">Document assignment is not possible: no Fundraising access categories defined</Alert>
    );
  }

  return (
    <FundraisingDocumentsContextProvider
      documentsState={documentsState}
      setDocumentsState={setDocumentsState}
      filteredDocuments={getFilteredDocuments(documentsState)}
      onPreviewFile={handlePreviewFile}
      onDownloadFile={handleDownloadFile}
      onDownloadMultipleFiles={handleDownloadMultipleFiles}
    >
      <Stack spacing={2} flex={1} pt={2} pb={2}>
        <FundraisingDocumentsUploadArea />
        <FundraisingDocumentsActions />
        <FundraisingDocumentsGrid />
      </Stack>

      {dialogState.openDialog === "preview" && dialogState.fileInfo && (
        <PreviewFileDialog
          url={dialogState.fileInfo.downloadUrl}
          fileName={dialogState.fileInfo.fileName}
          onClose={() => setDialogState({})}
        />
      )}
    </FundraisingDocumentsContextProvider>
  );
};

export default FundraisingDocuments;
