import AddIcon from "@mui/icons-material/AddRounded";
import { LoadingButton } from "@mui/lab";
import { Box, Button, List, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import { ApiError } from "../../../../../../../shared/api/types";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import adminApi from "../../../../../../api/adminApi";
import { Category } from "../../../../../../api/types/accessTypes";
import DragAndDropList from "../../../../../common/DragAndDropList";
import CategoryDragDropListItem from "./CategoryDragDropListItem";
import EditCategoryDialog from "./EditCategoryDialog";
import { getCategoryOrderUpdates } from "./catgoriesEditorHelper";

interface Props {
  categories: Category[];
  maxCategorySortOrder: number;
  readOnly?: boolean;
  onUpdated: () => void;
}

interface DialogState {
  open: boolean;
  editedCategory?: Category;
}

const updateFundraisingCategory = withErrorHandling(adminApi.updateFundraisingCategory);

const CategoriesEditor = ({ categories, maxCategorySortOrder, readOnly, onUpdated }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [editedCategories, setEditedCategories] = useState<Category[]>(categories);
  const [dialogState, setDialogState] = useState<DialogState>({ open: false });
  const [isSaving, setSaving] = useState(false);

  const orderUpdates = useMemo(
    () => getCategoryOrderUpdates(editedCategories, maxCategorySortOrder),
    [editedCategories, maxCategorySortOrder]
  );

  const handleSaveOrdering = async () => {
    if (orderUpdates.length === 0) {
      return;
    }

    setSaving(true);

    const updatedCategories: Category[] = [];
    const errors: ApiError[] = [];

    for (const [id, sortOrder] of orderUpdates) {
      const [result, error] = await updateFundraisingCategory(id, { sortOrder });
      if (error) {
        errors.push(error);
      } else {
        updatedCategories.push(result);
      }
    }

    setSaving(false);

    if (errors.length > 0) {
      logError(errors[0], "[CategoriesEditor] updateFundraisingCategory");
      sendNotificationError("Failed to update category orders");
    }

    if (updatedCategories.length > 0) {
      setEditedCategories((prev) =>
        prev.map((category) => {
          const updatedCategory = updatedCategories.find((c) => c.id === category.id);
          return updatedCategory ?? category;
        })
      );

      onUpdated();
    }
  };

  const handleResetOrdering = () => {
    setEditedCategories(categories);
  };

  const handleAddCategory = () => {
    setDialogState({ open: true });
  };

  const handleEditCategory = (editedCategory: Category) => {
    setDialogState({ open: true, editedCategory });
  };

  const handleCategoryUpdated = (updatedCategory: Category) => {
    setEditedCategories((prev) => {
      const isNew = prev.every((category) => category.id !== updatedCategory.id);

      return isNew
        ? [...prev, updatedCategory]
        : prev.map((category) => (category.id === updatedCategory.id ? updatedCategory : category));
    });

    setDialogState({ open: false });
    onUpdated();
  };

  const handleCategoryDeleted = (deletedCategoryId: string) => {
    setEditedCategories((prev) => prev.filter((category) => category.id !== deletedCategoryId));
    setDialogState({ open: false });
    onUpdated();
  };

  const handleItemDrop = (dropIndex: number, draggedCategory: Category | undefined) => {
    if (!draggedCategory) {
      return;
    }

    setEditedCategories((categories) => {
      const newCategories = categories.filter((cat) => cat.id !== draggedCategory.id);
      const insertIndex = dropIndex < 0 ? newCategories.length : dropIndex;
      newCategories.splice(insertIndex, 0, draggedCategory);
      return newCategories;
    });
  };

  const existingCategoryNames = editedCategories
    .filter((c) => c.id !== dialogState.editedCategory?.id)
    .map((c) => c.name);

  return (
    <>
      <Stack overflow="hidden" alignItems="flex-start" maxHeight="calc(100% - 160px)">
        {!readOnly && (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button variant="contained" startIcon={<AddIcon />} sx={{ ml: 2, mb: 2 }} onClick={handleAddCategory}>
              Add New
            </Button>
          </Box>
        )}

        <List sx={{ width: "100%", px: 0.5, py: 0, overflowY: "auto" }}>
          <DndProvider backend={HTML5Backend}>
            <DragAndDropList
              type="fundraising_category"
              items={editedCategories}
              onDrop={handleItemDrop}
              renderItem={(item) => (
                <CategoryDragDropListItem category={item} readOnly={readOnly || isSaving} onEdit={handleEditCategory} />
              )}
              options={{ notDraggable: () => readOnly || isSaving }}
            />
          </DndProvider>
        </List>

        <Stack px={2} py={1.5} spacing={1} direction="row" justifyContent="flex-end">
          <LoadingButton
            loading={isSaving}
            variant="contained"
            onClick={handleSaveOrdering}
            disabled={readOnly || orderUpdates.length === 0}
          >
            Save
          </LoadingButton>
          <Button variant="text" color="secondary" disabled={orderUpdates.length === 0} onClick={handleResetOrdering}>
            Cancel
          </Button>
        </Stack>
      </Stack>

      {dialogState.open && (
        <EditCategoryDialog
          editedCategory={dialogState.editedCategory}
          existingCategoryNames={existingCategoryNames}
          onUpdated={handleCategoryUpdated}
          onDeleted={handleCategoryDeleted}
          onClose={() => setDialogState({ open: false })}
        />
      )}
    </>
  );
};

export default CategoriesEditor;
