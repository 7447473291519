import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import type {} from "@mui/lab/themeAugmentation";
import { alpha, PaletteOptions } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import type {} from "@mui/x-charts/themeAugmentation";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";
import type {} from "@mui/x-date-pickers-pro/themeAugmentation";
import React from "react";
import ArrowDropDownMediumRoundedIcon from "./icons/ArrowDropDownMediumRoundedIcon";
import ArrowRightMediumRoundedIcon from "./icons/ArrowRightMediumRoundedIcon";

declare module "@mui/material/styles" {
  interface TypeBackground {
    grey: string;
  }

  interface PaletteOptions {
    tooltip: {
      fill: string;
    };
    dialogIcon: string;
  }
  interface Palette {
    tooltip: {
      fill: string;
    };
    dialogIcon: string;
  }
}

const lightPaletteColors: PaletteOptions = {
  primary: {
    main: "#00A866",
    dark: "#009066",
    light: "#32CA7F",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#647381",
    dark: "#303A48",
    light: "#8E9BA9",
    contrastText: "#FFFFFF",
  },
  action: {
    active: "#8E9BA9",
    hover: "rgba(35, 52, 59, 0.04)",
    selected: "#EEEFF0",
    disabledBackground: "#F1F1F1",
    focus: "rgba(100, 115, 129, 0.12)",
    disabled: "#C9CFD6",
  },
  text: {
    primary: "#23343B",
    secondary: "#647381",
    disabled: "#B4BDC6",
  },
  info: {
    main: "#0288D1",
    dark: "#01579B",
    light: "#03A9F4",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF5350",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#ED6C02",
    dark: "#E65100",
    light: "#FF9800",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
    light: "#4CAF50",
    contrastText: "#FFFFFF",
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
    grey: "#FAFAFA",
  },
  divider: "#E5E6E9",
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  tooltip: {
    fill: "#23343B",
  },
  dialogIcon: "#C7DBE5",
};

const darkPaletteColors: PaletteOptions = {
  primary: {
    main: "#00A866",
    dark: "#009066",
    light: "#32CA7F",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#647381",
    dark: "#303A48",
    light: "#8E9BA9",
    contrastText: "#FFFFFF",
  },
  action: {
    active: "#8E9BA9",
    hover: "#202427",
    selected: "#262A2F",
    disabledBackground: "#2E3236",
    disabled: "#464B50",
    focus: "rgba(100, 115, 129, 0.12)",
  },
  text: {
    primary: "#EEEFF1",
    secondary: "#91A4B6",
    disabled: "#525960",
  },
  info: {
    main: "#0288D1",
    dark: "#01579B",
    light: "#03A9F4",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#D32F2F",
    dark: "#C62828",
    light: "#EF5350",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#ED6C02",
    dark: "#E65100",
    light: "#FF9800",
    contrastText: "#FFFFFF",
  },
  success: {
    main: "#2E7D32",
    dark: "#1B5E20",
    light: "#4CAF50",
    contrastText: "#FFFFFF",
  },
  background: {
    default: "#15181C",
  },
  divider: "#263238",
  common: {
    black: "#000000",
    white: "#FFFFFF",
  },
  tooltip: {
    fill: "#23343B",
  },
  dialogIcon: "#C7DBE5",
};

const scrollbarColor = "#cccaca";
const activeScrollbarColor = "#959595";

const commonThemeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& *::-webkit-scrollbar": {
            background: "transparent",
            width: "16px",
          },
          "& *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            minHeight: 24,
            border: "4px solid transparent",
            backgroundClip: "padding-box",
          },
          "& *:hover::-webkit-scrollbar-thumb": {
            backgroundColor: scrollbarColor,
          },
          "& *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: activeScrollbarColor,
          },
          "& *::-webkit-scrollbar-thumb:active": {
            backgroundColor: activeScrollbarColor,
          },
          "& *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: activeScrollbarColor,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const borderColor = ownerState.severity ? theme.palette[ownerState.severity].main : undefined;
          return {
            ...(borderColor &&
              ownerState.variant === "standard" && {
                border: `1px solid ${alpha(borderColor, 0.5)}`,
              }),
          };
        },
        message: ({ theme }) => ({ ...theme.typography.body1 }),
      },
      defaultProps: {
        iconMapping: {
          warning: React.createElement(WarningAmberRoundedIcon),
          success: React.createElement(CheckCircleOutlineRoundedIcon),
          error: React.createElement(ErrorOutlineRoundedIcon),
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "small",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        outlinedSecondary: ({ theme }) => ({
          borderColor: theme.palette.divider,
          ":hover": {
            borderColor: theme.palette.divider,
          },
          "&.Mui-disabled": {
            borderColor: theme.palette.divider,
          },
        }),
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.action.active,
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.action.active,
        }),
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        track: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
        }),
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: (theme) => ({
          textTransform: "none",
          color: theme.theme.palette.secondary.main,
          "&.Mui-selected": {
            backgroundColor: alpha(theme.theme.palette.text.primary, 0.04),
          },
        }),
        sizeSmall: {
          padding: "4px 10px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "36px",
          borderBottom: "1px solid",
          borderColor: theme.palette.divider,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "3px 16px",
          minHeight: "36px",
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInput: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSelect: {
      defaultProps: {
        size: "small",
        IconComponent: ArrowDropDownRoundedIcon,
      },
      styleOverrides: {
        iconStandard: {
          top: "calc(50% - 12px)",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
        popupIcon: React.createElement(ArrowDropDownRoundedIcon),
      },
      styleOverrides: {
        root: {
          "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(12px, 0px) scale(0.75)",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          "& .MuiInputLabel-filled.MuiInputLabel-shrink": {
            transform: "translate(12px, -1px) scale(0.75)",
          },
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&.Mui-selected": {
            color: theme.palette.primary.main,
            ".MuiTypography-root": {
              color: theme.palette.primary.main,
            },
          },
        }),
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          "&:last-child": {
            paddingBottom: theme.spacing(2),
          },
        }),
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.divider,
          ...theme.typography.body1,
        }),
        head: {
          fontWeight: 500,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: ({ theme }) => ({
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: ({ theme }) => ({
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.secondary,
        }),
        colorError: ({ theme }) => ({
          backgroundColor: "#FDEDED",
          color: theme.palette.error.main,
        }),
      },
    },
    MuiSlider: {
      defaultProps: {
        size: "small",
      },
    },
    MuiPagination: {
      defaultProps: {
        size: "small",
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiDateField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSingleInputDateRangeField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({ backgroundColor: theme.palette.tooltip.fill }),
        arrow: ({ theme }) => ({ color: theme.palette.tooltip.fill }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: 0,
          ...theme.typography.body1,
          ".MuiDataGrid-columnHeaders": {
            borderColor: theme.palette.divider,
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          },
          ".MuiDataGrid-columnHeader": {
            "&.MuiDataGrid-columnHeader--withLeftBorder": {
              borderLeftColor: theme.palette.divider,
            },
            "&.MuiDataGrid-columnHeader--withRightBorder": {
              borderRightColor: theme.palette.divider,
            },
            "&.MuiDataGrid-withBorderColor": {
              borderColor: theme.palette.divider,
            },

            "&:focus": {
              outline: "none",
            },
          },
          ".MuiDataGrid-row": {
            "&:hover, &.Mui-hovered": {
              ".MuiDataGrid-cell.grid-row-actions": {
                button: {
                  visibility: "visible",
                },
                a: {
                  visibility: "visible",
                },
              },
              ".highlighted-action": {
                color: theme.palette.primary.main,
              },
            },
            ".MuiDataGrid-cell": {
              outline: "none",
              borderTopColor: theme.palette.divider,
              display: "flex",
              alignItems: "center",

              "&.MuiDataGrid-cell--withLeftBorder": {
                borderLeftColor: theme.palette.divider,
              },
              "&.MuiDataGrid-cell--withRightBorder": {
                borderRightColor: theme.palette.divider,
              },
            },
            "&.MuiDataGrid-row--firstVisible": {
              ".MuiDataGrid-cell": {
                borderTopColor: "transparent",

                "&.MuiDataGrid-cellEmpty": {
                  borderTopColor: theme.palette.divider,
                },
              },
            },
          },
          ".MuiDataGrid-cell": {
            "&.grid-row-actions": {
              button: {
                visibility: "hidden",
              },
              a: {
                visibility: "hidden",
              },
            },
          },
          ".MuiDataGrid-overlayWrapperInner": {
            position: "absolute",
          },
          "&:hover": {
            "& ::-webkit-scrollbar-thumb": {
              backgroundColor: scrollbarColor,
            },
            "& ::-webkit-scrollbar-thumb:focus": {
              backgroundColor: activeScrollbarColor,
            },
            "& ::-webkit-scrollbar-thumb:active": {
              backgroundColor: activeScrollbarColor,
            },
            "& ::-webkit-scrollbar-thumb:hover": {
              backgroundColor: activeScrollbarColor,
            },
          },
        }),
      },
      defaultProps: {
        slots: {
          groupingCriteriaCollapseIcon: ArrowDropDownMediumRoundedIcon,
          groupingCriteriaExpandIcon: ArrowRightMediumRoundedIcon,
          treeDataCollapseIcon: ArrowDropDownMediumRoundedIcon,
          treeDataExpandIcon: ArrowRightMediumRoundedIcon,
        },
      },
    },
    MuiChartsAxis: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiChartsAxis-line": { stroke: theme.palette.divider },
          ".MuiChartsAxis-tick": { stroke: theme.palette.divider },
          ".MuiChartsAxis-tickLabel": { fill: theme.palette.text.secondary },
        }),
      },
    },
  },
  typography: {
    h1: { fontSize: 32, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "-0.5px" },
    h2: { fontSize: 28, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "-0.5px" },
    h3: { fontSize: 24, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%" },
    h4: { fontSize: 20, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%", letterSpacing: "0.25px" },
    h5: { fontSize: 18, fontWeight: 600, fontFamily: "Roboto", lineHeight: "120%" },
    h6: { fontSize: 15, fontWeight: 600, fontFamily: "Roboto", lineHeight: "130%", letterSpacing: "0.15px" },
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: 13,
      fontWeight: 500,
      fontFamily: "Roboto",
      lineHeight: "140%",
      letterSpacing: "0.1px",
    },
    body1: { fontSize: 13, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.15px" },
    body2: { fontSize: 12, fontWeight: 400, fontFamily: "Roboto", lineHeight: "140%", letterSpacing: "0.17px" },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "0.4px",
    },
    button: { fontSize: 13, fontWeight: 500, fontFamily: "Roboto", lineHeight: "24px" },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Roboto",
      lineHeight: "120%",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 579,
      md: 760,
      lg: 1024,
      xl: 1600,
    },
  },
};

export const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    ...lightPaletteColors,
  },
  ...commonThemeOptions,
};

export const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    ...darkPaletteColors,
  },
  ...commonThemeOptions,
  components: {
    ...commonThemeOptions.components,
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "rgba(0, 168, 102, 0.5)",
        },
      },
    },
  },
};
